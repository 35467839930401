import styled from "styled-components"

const Button = styled.a`
  margin: 20px 0;
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 4px;

  &:hover {
    text-decoration: none;
    background-color: white;
    color: black;
    border: 2px solid black;
  }
`

export default Button
